import { Outlet } from "react-router-dom";
import "./style.scss";
import { ChatIcon, InsightsIcon } from "../../assets/component_icons";
import NavBar, { NavBarNode } from "../../components/navbar";

import { isDesktopVersion } from "../../utils/misc";

const Main = () => {
  const headers: NavBarNode[] = [
    {
      path: "/",
      headerText: "Dashboard",
      headerIcon: <InsightsIcon />,
      subPaths: [
        {
          path: "/report",
          headerText: <div>Cough Report</div>,
        },
        {
          path: "/trend",
          headerText: <div>Cough Trend</div>,
        },
        {
          path: "/bursts",
          headerText: <div>Cough Bursts</div>,
        },
        {
          path: "/rate",
          headerText: <div>Cough Rate</div>,
        },
      ],
    },
    {
      path: "/community",
      headerText: "Community",
      headerIcon: <ChatIcon />,
    },
  ];

  return (
    <div className="main">
      <NavBar structure={headers} lvl1_disable={!isDesktopVersion()}></NavBar>
      <div className="main_container">
        <Outlet />
      </div>
    </div>
  );
};

export default Main;
