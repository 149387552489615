import { useCallback, useEffect } from "react";
import { IdTokenResult, getAuth, onAuthStateChanged } from "firebase/auth";

import amplitude from "../lib/analytics/amplitude";
import { backOff } from "exponential-backoff";
import { useRecoilState } from "recoil";

import { Dayjs } from "dayjs";
import { User } from "firebase/auth";
import { atom } from "recoil";
import axios, { AxiosResponse } from "axios";

type HyfeInsightsClaims = IdTokenResult["claims"] & {
  hyfe: {
    insights: {
      customer_id: string;
      uid: string;
    };
  };
};

export interface AuthStateInterface {
  user: Partial<User & { claims: HyfeInsightsClaims }> | null | undefined;
  isAnonymous: boolean | undefined;
  isFbAuthInit: boolean | undefined;
  isHyfeStaff: boolean | undefined;
}

const defaultState: AuthStateInterface = {
  user: undefined,
  isAnonymous: undefined,
  isFbAuthInit: undefined,
  isHyfeStaff: undefined,
};

export const AuthState = atom({
  key: "Globals.AuthState",
  default: defaultState,
  dangerouslyAllowMutability: true,
});

const strFacebookProvider = "facebook.com";
export const useFirebaseAuthConnector = () => {
  const [authState, setAuthState] = useRecoilState(AuthState);

  useEffect(() => {
    const auth = getAuth();
    const unsub = onAuthStateChanged(auth, async (user) => {
      // After firebase auth initializes it will init the user object
      // if credentials were in local storage user will be filled otherwise it will be null
      setAuthState((prevState) => ({ ...prevState, isFbAuthInit: true }));
      if (user) {
        // Important to set isAnonymous before user.
        // This way checks like:
        // !user ? (loading state) : isAnonymous ? (anonymous content) : (non-anonymous content)
        // work as expected and don't show non-anonymous content for brief moment.
        const isFacebookLogin =
          user.providerData.findIndex((profile) => profile.providerId === strFacebookProvider) >= 0;
        const idTokenResult = await user.getIdTokenResult();
        setAuthState((prevState) => ({
          ...prevState,
          isAnonymous:
            user.isAnonymous ||
            (user.email === null && user.phoneNumber === null && !isFacebookLogin) ||
            user.providerData.length === 0,
          user: {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            metadata: { creationTime: user.metadata.creationTime },
            photoURL: user.photoURL,
            claims: idTokenResult.claims as HyfeInsightsClaims,
          },
          isHyfeStaff:
            (user.email?.endsWith("@hyfe.ai") ||
              user.email?.endsWith("@hyfeapp.com") ||
              user.email?.endsWith("@hyfe.com")) &&
            user.emailVerified,
        }));

        // Notify amplitude of user login
        amplitude.setUserId(user.uid);
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          user: null,
          isAnonymous: undefined,
          isHyfeStaff: undefined,
        }));
        amplitude.setUserId(undefined);
      }
    });
    return unsub;
  }, [setAuthState]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.group("Auth State");
      Object.keys(authState).forEach((key) => console.log(key, (authState as any)[key]));
      console.groupEnd();
    }
  }, [authState]);
};
